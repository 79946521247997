<template>
  <div
    class="bg-white p-6 pt-0  sm:pt-0  m-0 mt-12 sm:m-0 rounded-lg modal-md "
  >
    <div
      class="bg-teal-400 relative -ml-6 -mr-6 sm:-ml-6 sm:-mr-6 h-48 flex justify-center rounded-t-lg"
    >
      <img
        src="/images/5ormore.webp"
        class="w-f absolute bottom-0 -mb-12  w-4/5 lg:-mt-20  "
        alt=""
      />
    </div>
    <div class="text-lg my-6  mt-16 mb-4 font-semibold font-merri text-center">
      Helping you give more variety
    </div>

    <div class="px-1 pb-2 text-center">
      <p>
        With just 5 or more of any flavour, you can mix up their order as much
        as you like!
      </p>
    </div>

    <form @submit.prevent="closeModal" novalidate="true">
      <loading-button
        :is-loading="false"
        :is-enabled="true"
        :is-full="true"
        class="mt-2"
        label="Ok, thanks!"
      />
    </form>
  </div>
</template>

<script>
import LoadingButton from '@/components/LoadingButton';

export default {
  components: {
    LoadingButton
  },
  methods: {
    closeModal: function() {
      this.$store.dispatch('sendMpEvent', {
        event: 'Closed mix match',
        properties: {
          location: this.$route.name,
          type: 'EVT'
        }
      });

      this.$parent.$emit('make-close');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-md {
  max-width: 420px;
  width: auto;
}

.new-teal {
  background: #76dadc;
}
</style>
